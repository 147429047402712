body {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}

a, u {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}

a.hover {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}

a.active {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}