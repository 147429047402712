.itemlist {
  margin-bottom: 2px;
  margin-top: 2px;
  background-color: rgba(156, 230, 255, 0.2);
  flex-wrap: nowrap;
}

.testId {
  padding-top: 10px;
  font-weight: bold;
  text-align: center;
}

.dogId {
  padding-top: 10px;
  text-align: center;
}

.email {
  padding-top: 10px;
}

.testPos {
  width: 100%;
  height: 100%;
}

.testPosCellZero{
  background-color:rgba(206, 206, 206, 0.6);
  color: #ffffff;
  font-weight: bold;
}

.testPosCellOne{
  background-color:#99e5ff;
  color: #ffffff;
  font-weight: bold;
}

.testPosCellTwo{
  background-color:#33ccff;
  color: #ffffff;
  font-weight: bold;
}

.testResultGreen{
  background-color:#a0c864;
}

.testResultOrange{
  background-color:#f2c736;
}

.testResultRed{
  background-color:#ec6f6e;
}

.checkBoxZero {
  border-radius: 3px;
  border-width: 2px;
  border-style: unset;
  background-color: none;
  border-color: green;
}

.checkBoxZeroActive {
  border-radius: 3px;
  background-color: #33ccff;
  border-width: 2px;
}

.dogName {
  padding-top: 10px;
}

.date {
  padding-top: 10px;
}

.headerRow {
  background-color: rgba(126, 152, 230);
}

.row {
  background-color: rgb(242, 246, 255);
}

.filter {
  margin: 2px;
  margin-bottom: 5px;
  background-color: rgba(206, 206, 206, 0.2);
  border-radius: 30px 30px 0 0;
  padding-left: 10px;
}

.datePickerContainer{
  margin:10px;
}

.input {
  border-radius: 30px 30px 0 0;
  width: 80%;
}

.searchIcon {
  width: 24px;
  height: 24px;
}

.datePicker{
  border: none;
}

.MuiIconButton-root-210 {
  padding: 0px;
}

.noChecksFound {
  text-align: center;
}
