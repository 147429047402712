.card {
  width: 29%;
  height: 200px;
  margin: 20px;
}

.cardOne {
  background-color: #7e98e6;
  height: 100%;
}

.cardTwo {
  background-color: #0070ff;
  height: 100%;
}

.cardThree {
  background-color: #21305e;
  height: 100%;
}

.smallHeader {
  color: #ffffff;
}

.cardHeader {
  font-family: "Work Sans", "Arial", sans-serif;
  font-weight: bolder;
  font-size: 36px;
  margin-bottom: 10px;
}

.cardInside{
  height: 100%;
}
