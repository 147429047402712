.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #000000;
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #264c59;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu {
  height: 100%; 
  color: white;
}

.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.hover {
  color: "#33CCFF";
}

.active {
  color: "#33CCFF";
}

.baseHeader {
  font-family: "Work Sans", "Arial", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: "#4769cc";
  margin: 0;
  padding: 0;
}

.baseHeaderSmall {
  font-family: "Work Sans", "Arial", sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.tabs {
  text-align: start;
  height: 100%;
  background-color: "#4769cc";
  color: white;
  padding-bottom: 20px;
}

.pageHeader {
  width: 85%;
  padding: 0;
}

.pageContent {
  height: 100%;
  margin: 20px;
  flex-wrap: nowrap;
}

.pageHeaderSmall {
  margin-top: 15%;
  width: 100%;
  padding: 10px;
}

.menuContent {
  height: 100%;
  width: 15%;
  color: "white";
}

.menuContentSmall {
  height: 100vh;
  width: 100%;
}

.appBarContent {
  width: 100%;
  background-color: rgb(30, 121, 121);
}

.logo{
  padding-right:10px;
  margin-top: 5px;
  margin-left: 15px;
}

.logoContainer {
  margin-top: 20px;
  text-align: center;
  align-items: center;
}

.textBold {
  font-size: 12px;
  color: white;
  margin-left: 20px;
}

.copyRightContainer {
  margin-top: 10%;
}