.developListContainer{
    margin-top: 5px ;
    padding: 5px;
}

.filterHistory{
    width: 100%;
    margin-top: 20px;
}

.skeleton {
    margin: 0;
}

.detailText {
    font-family: "Work Sans";
    color: lightgray;
    font-size: 10;
    padding-right: 25px;
}

.text{
    font-family: "Work Sans";
    font-weight: bold;
    color: "#21305e";
    font-size: 12pt;
    margin-top: 0px;
    margin-right: 10px;
}
.smallText {
    font-family: "Work Sans";
    color: "#21305e";
    font-size: 10pt;
}
