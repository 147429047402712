body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Work Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #000000;
  height: 100%;
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #264c59;
} */

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu {
  height: 100%; 
  color: white;
}

.MuiGrid-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  box-sizing: border-box;
}

.hover {
  color: "#33CCFF";
}

.active {
  color: "#33CCFF";
}

.baseHeader {
  font-family: "Work Sans", "Arial", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: "#4769cc";
  margin: 0;
  padding: 0;
}

.baseHeaderSmall {
  font-family: "Work Sans", "Arial", sans-serif;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.tabs {
  text-align: start;
  height: 100%;
  background-color: "#4769cc";
  color: white;
  padding-bottom: 20px;
}

.pageHeader {
  width: 85%;
  padding: 0;
}

.pageContent {
  height: 100%;
  margin: 20px;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.pageHeaderSmall {
  margin-top: 15%;
  width: 100%;
  padding: 10px;
}

.menuContent {
  height: 100%;
  width: 15%;
  color: "white";
}

.menuContentSmall {
  height: 100vh;
  width: 100%;
}

.appBarContent {
  width: 100%;
  background-color: rgb(30, 121, 121);
}

.logo{
  padding-right:10px;
  margin-top: 5px;
  margin-left: 15px;
}

.logoContainer {
  margin-top: 20px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
}

.textBold {
  font-size: 12px;
  color: white;
  margin-left: 20px;
}

.copyRightContainer {
  margin-top: 10%;
}

.itemlist {
  margin-bottom: 2px;
  margin-top: 2px;
  background-color: rgba(156, 230, 255, 0.2);
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.testId {
  padding-top: 10px;
  font-weight: bold;
  text-align: center;
}

.dogId {
  padding-top: 10px;
  text-align: center;
}

.email {
  padding-top: 10px;
}

.testPos {
  width: 100%;
  height: 100%;
}

.testPosCellZero{
  background-color:rgba(206, 206, 206, 0.6);
  color: #ffffff;
  font-weight: bold;
}

.testPosCellOne{
  background-color:#99e5ff;
  color: #ffffff;
  font-weight: bold;
}

.testPosCellTwo{
  background-color:#33ccff;
  color: #ffffff;
  font-weight: bold;
}

.testResultGreen{
  background-color:#a0c864;
}

.testResultOrange{
  background-color:#f2c736;
}

.testResultRed{
  background-color:#ec6f6e;
}

.checkBoxZero {
  border-radius: 3px;
  border-width: 2px;
  border-style: unset;
  background-color: none;
  border-color: green;
}

.checkBoxZeroActive {
  border-radius: 3px;
  background-color: #33ccff;
  border-width: 2px;
}

.dogName {
  padding-top: 10px;
}

.date {
  padding-top: 10px;
}

.headerRow {
  background-color: rgba(126, 152, 230);
}

.row {
  background-color: rgb(242, 246, 255);
}

.filter {
  margin: 2px;
  margin-bottom: 5px;
  background-color: rgba(206, 206, 206, 0.2);
  border-radius: 30px 30px 0 0;
  padding-left: 10px;
}

.datePickerContainer{
  margin:10px;
}

.input {
  border-radius: 30px 30px 0 0;
  width: 80%;
}

.searchIcon {
  width: 24px;
  height: 24px;
}

.datePicker{
  border: none;
}

.MuiIconButton-root-210 {
  padding: 0px;
}

.noChecksFound {
  text-align: center;
}

.developListContainer{
    margin-top: 5px ;
    padding: 5px;
}

.filterHistory{
    width: 100%;
    margin-top: 20px;
}

.skeleton {
    margin: 0;
}

.detailText {
    font-family: "Work Sans";
    color: lightgray;
    font-size: 10;
    padding-right: 25px;
}

.text{
    font-family: "Work Sans";
    font-weight: bold;
    color: "#21305e";
    font-size: 12pt;
    margin-top: 0px;
    margin-right: 10px;
}
.smallText {
    font-family: "Work Sans";
    color: "#21305e";
    font-size: 10pt;
}

.card {
  width: 29%;
  height: 200px;
  margin: 20px;
}

.cardOne {
  background-color: #7e98e6;
  height: 100%;
}

.cardTwo {
  background-color: #0070ff;
  height: 100%;
}

.cardThree {
  background-color: #21305e;
  height: 100%;
}

.smallHeader {
  color: #ffffff;
}

.cardHeader {
  font-family: "Work Sans", "Arial", sans-serif;
  font-weight: bolder;
  font-size: 36px;
  margin-bottom: 10px;
}

.cardInside{
  height: 100%;
}

.newChecksDiv{
    margin: 20px;
}

.blueText{
    font-weight: bold;
    color: "#4769cc";
    padding: 2px;
    background-color: "#e6eeff";
    font-size: 14pt;
    border-radius: 2px;
    font-family: "Work Sans", "Arial", sans-serif;
}

.detailText {
    color: lightgray;
    font-size: 10;
    padding-right: 25px;
    font-family: 'Work Sans', sans-serif;
}

.text{
    font-weight: bold;
    font-size: 12pt;
    font-family: "Work Sans", "Arial", sans-serif;
}
    
.listHeader{
    margin-top: 2px;
}

.list {
    margin: 10px;
}
.dogInfoListItemText {
    color: "#4769cc";
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.button {
  margin-right: 10;
}

.backButton {
  margin-right: 10;
}

.completed {
  display: "inline-block";
}

.completedStep {
  font-size: small;
}

.parameterInfosContainer {
  width: "auto"
}

.instructions {
  margin-top: 10;
}

.sliderContainer {
  width: 100%;
}

.sliderContainer {
  width: 20%;
  height: 50%;
}

.contentContainer {
  margin-left: 20px;
}

.completeSliderContainer{
  margin: 0;
  width: 100%;
  height: auto;
}

.imageRotation {
  margin: 10px;
  -webkit-transform:rotate(90deg);
  transform: rotate(90deg);
}

.imageCrop {
  border-radius: 10px;
}

.parameterText {
  font-family: 'Work Sans';
  font-weight: bold;
  font-size: 14px;
}

.resultText{
  font-family: 'Work Sans';
  font-size: 14px;
}

.buttonMargin {
  margin: 5px;
}

.paper {
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  width: 90%;
  background-color: "#4769cc";
  color: white;
  font-weight: bold;
}

.tableHeader {
  color: white;
  font-weight: 600;
  line-height: 1.5rem;
}

.tableBody {
  color: white;
  font-weight: 400;
  line-height: 1.5rem;
}

.MuiTableCell-body {
  color: white;
}

@media only screen and (max-width: 600px) {
  .resultText{
    font-size: 12px;
  }
}
.dogInfoListItemText {
    font-family: "Work Sans";
    color: "#21305e";
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.resultColor {
    width: 35px;
    height: 35px;
    background-color: "#3d8e8e93";
    border-radius: 20px;
}

.italicStyleText {
    font-family: "Work Sans";
    font-style: italic;
    font-size: 12px;
    padding-left: 2px;
}

.boldTextInfos {
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: bold;
    color: "#21305e";
}

.infoCard{
    margin-top: 10px;
    margin-bottom: 10px;
}

.textInfos {
    font-family: "Work Sans";
    font-size: 12px;
    color: "#21305e";
}

.blueText{
    font-family: "Work Sans";
    font-weight: bold;
    color: "#4769cc";
    padding: 2px;
    background-color: "#e6eeff";
    font-size: 14pt;
    border-radius: 2px;
}

.detailText {
    font-family: "Work Sans";
    color: lightgray;
    font-size: 10pt;
    padding-right: 25px;
}

.text{
    font-family: "Work Sans";
    font-weight: bold;
    font-size: 12pt;
}

.resultNeutral {
    font-family: "Work Sans";
    width: 40px;
    height: 40px;
    background-color: lightgray;
    border-radius: 20%;
  }
.resultIconSize {
  width: 30px;
  height: 30px;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  padding: 5px;
}

.resultIconSizeSmall {
  width: 20px;
  height: 20px;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  padding: 1px;
}

.resultColorGreen {
  background-color: #a0c864;
  text-align: center;
  border-radius: 5px;
}

.resultColorOrange {
  text-align: center;
  background-color: #f2c736;
  border-radius: 5px;
}

.resultColorRed {
  text-align: center;
  background-color: #ec6f6e;
  border-radius: 5px;
}

.resultColorGrey {
  text-align: center;
  background-color: grey;
  border-radius: 5px;
}

.resultColorGreenSmall {
  width: 25px;
  height: 25px;
  background-color: #a0c864;
  border-radius: 5px;
}

.resultColorOrangeSmall {
  width: 25px;
  height: 25px;
  background-color: #f2c736;
  border-radius: 5px;
}

.resultColorRedSmall {
  width: 25px;
  height: 25px;
  background-color: #ec6f6e;
  border-radius: 5px;
}

.resultTextContainer {
  margin-left: 10px;
}

.textResult {
  font-size: 14px;
  color: "#21305e";
  margin-top: 2px;
  margin-left: 10px;
  padding: 0;
  margin-bottom: 0;
  font-family: "Work Sans", "Arial", sans-serif;
}

.textSmallResult {
  font-size: 12px;
  color: "#21305e";
  margin-left: 10px;
  margin-top: 1px;
  font-family: 'Work Sans', sans-serif;
}

.resultInfoContainer {
  width: 100%;
  padding: 2px;
}

.MuiGrid-root.resultInfoContainer.MuiGrid-container.MuiGrid-grid-xs-3 {
  max-width: 100%;
}

.nonflex {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

.blueText{
    font-family: "Work Sans";
    font-weight: bold;
    color: "#4769cc";
    padding: 2px;
    background-color: "#e6eeff";
    font-size: 14pt;
    border-radius: 2px;
}

.detailText {
    font-family: "Work Sans";
    color: lightgray;
    font-size: 10;
    padding-right: 25px;
}

.text{
    font-family: "Work Sans";
    font-weight: bold;
    font-size: 12pt;
    color: "#21305e";
    margin-top: 0px;
    margin-right: 10px;
}
.smallText {
    font-family: "Work Sans";
    font-size: 10pt;
    color: "#21305e";
}

.infoCard{
    font-family: "Work Sans";
    color: "#21305e";
    margin-top: 10px;
    margin-bottom: 10px;
}

.anamnesisDott {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 10px;
}
.textStyle {
    font-family: 'Work Sans', Courier, monospace;
}

.buttonContainer {
    width: 100%;
}
.statisticsContainer {
    margin-top: 30px;
}

.textStatistics {
    font-weight: bold;
    font-size: 12pt;
    margin-top: 10px;
    text-align: center;
}

.counter{
    font-weight: bold;
    font-size: 18pt;
    color: "#21305e";
}



body {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}

a, u {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}

a.hover {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}

a.active {
    text-decoration: none;
    color: "#264c59";
    font-size: 18px;
}
.teamCard {
    width: 25%;
    height: auto;
    margin-top: 30px;
}
.headerRow {
  background-color: rgba(126, 152, 230);
  color: "#21305e";
}

.headerRow :hover {
    color: "#21305e";
}

.headerRow {
  background-color: rgba(206, 206, 206, 0.8);
  color: "#21305e";
}

.headerRow :hover {
    color: "#21305e";
}

.loginCard {
    width: "100%";
    height: "100%"
}
