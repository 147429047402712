.resultColor {
    width: 35px;
    height: 35px;
    background-color: "#3d8e8e93";
    border-radius: 20px;
}

.italicStyleText {
    font-family: "Work Sans";
    font-style: italic;
    font-size: 12px;
    padding-left: 2px;
}

.boldTextInfos {
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: bold;
    color: "#21305e";
}

.infoCard{
    margin-top: 10px;
    margin-bottom: 10px;
}

.textInfos {
    font-family: "Work Sans";
    font-size: 12px;
    color: "#21305e";
}

.blueText{
    font-family: "Work Sans";
    font-weight: bold;
    color: "#4769cc";
    padding: 2px;
    background-color: "#e6eeff";
    font-size: 14pt;
    border-radius: 2px;
}

.detailText {
    font-family: "Work Sans";
    color: lightgray;
    font-size: 10pt;
    padding-right: 25px;
}

.text{
    font-family: "Work Sans";
    font-weight: bold;
    font-size: 12pt;
}

.resultNeutral {
    font-family: "Work Sans";
    width: 40px;
    height: 40px;
    background-color: lightgray;
    border-radius: 20%;
  }