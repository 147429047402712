.button {
  margin-right: 10;
}

.backButton {
  margin-right: 10;
}

.completed {
  display: "inline-block";
}

.completedStep {
  font-size: small;
}

.parameterInfosContainer {
  width: "auto"
}

.instructions {
  margin-top: 10;
}

.sliderContainer {
  width: 100%;
}

.sliderContainer {
  width: 20%;
  height: 50%;
}

.contentContainer {
  margin-left: 20px;
}

.completeSliderContainer{
  margin: 0;
  width: 100%;
  height: auto;
}

.imageRotation {
  margin: 10px;
  -webkit-transform:rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.imageCrop {
  border-radius: 10px;
}

.parameterText {
  font-family: 'Work Sans';
  font-weight: bold;
  font-size: 14px;
}

.resultText{
  font-family: 'Work Sans';
  font-size: 14px;
}

.buttonMargin {
  margin: 5px;
}

.paper {
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  width: 90%;
  background-color: "#4769cc";
  color: white;
  font-weight: bold;
}

.tableHeader {
  color: white;
  font-weight: 600;
  line-height: 1.5rem;
}

.tableBody {
  color: white;
  font-weight: 400;
  line-height: 1.5rem;
}

.MuiTableCell-body {
  color: white;
}

@media only screen and (max-width: 600px) {
  .resultText{
    font-size: 12px;
  }
}