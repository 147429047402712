.newChecksDiv{
    margin: 20px;
}

.blueText{
    font-weight: bold;
    color: "#4769cc";
    padding: 2px;
    background-color: "#e6eeff";
    font-size: 14pt;
    border-radius: 2px;
    font-family: "Work Sans", "Arial", sans-serif;
}

.detailText {
    color: lightgray;
    font-size: 10;
    padding-right: 25px;
    font-family: 'Work Sans', sans-serif;
}

.text{
    font-weight: bold;
    font-size: 12pt;
    font-family: "Work Sans", "Arial", sans-serif;
}
    
.listHeader{
    margin-top: 2px;
}

.list {
    margin: 10px;
}