.statisticsContainer {
    margin-top: 30px;
}

.textStatistics {
    font-weight: bold;
    font-size: 12pt;
    margin-top: 10px;
    text-align: center;
}

.counter{
    font-weight: bold;
    font-size: 18pt;
    color: "#21305e";
}