.nonflex {
    flex-wrap: nowrap;
}

.blueText{
    font-family: "Work Sans";
    font-weight: bold;
    color: "#4769cc";
    padding: 2px;
    background-color: "#e6eeff";
    font-size: 14pt;
    border-radius: 2px;
}

.detailText {
    font-family: "Work Sans";
    color: lightgray;
    font-size: 10;
    padding-right: 25px;
}

.text{
    font-family: "Work Sans";
    font-weight: bold;
    font-size: 12pt;
    color: "#21305e";
    margin-top: 0px;
    margin-right: 10px;
}
.smallText {
    font-family: "Work Sans";
    font-size: 10pt;
    color: "#21305e";
}

.infoCard{
    font-family: "Work Sans";
    color: "#21305e";
    margin-top: 10px;
    margin-bottom: 10px;
}

.anamnesisDott {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 10px;
}