.resultIconSize {
  width: 30px;
  height: 30px;
  align-items: center;
  text-align: center;
  padding: 5px;
}

.resultIconSizeSmall {
  width: 20px;
  height: 20px;
  align-items: center;
  text-align: center;
  padding: 1px;
}

.resultColorGreen {
  background-color: #a0c864;
  text-align: center;
  border-radius: 5px;
}

.resultColorOrange {
  text-align: center;
  background-color: #f2c736;
  border-radius: 5px;
}

.resultColorRed {
  text-align: center;
  background-color: #ec6f6e;
  border-radius: 5px;
}

.resultColorGrey {
  text-align: center;
  background-color: grey;
  border-radius: 5px;
}

.resultColorGreenSmall {
  width: 25px;
  height: 25px;
  background-color: #a0c864;
  border-radius: 5px;
}

.resultColorOrangeSmall {
  width: 25px;
  height: 25px;
  background-color: #f2c736;
  border-radius: 5px;
}

.resultColorRedSmall {
  width: 25px;
  height: 25px;
  background-color: #ec6f6e;
  border-radius: 5px;
}

.resultTextContainer {
  margin-left: 10px;
}

.textResult {
  font-size: 14px;
  color: "#21305e";
  margin-top: 2px;
  margin-left: 10px;
  padding: 0;
  margin-bottom: 0;
  font-family: "Work Sans", "Arial", sans-serif;
}

.textSmallResult {
  font-size: 12px;
  color: "#21305e";
  margin-left: 10px;
  margin-top: 1px;
  font-family: 'Work Sans', sans-serif;
}

.resultInfoContainer {
  width: 100%;
  padding: 2px;
}

.MuiGrid-root.resultInfoContainer.MuiGrid-container.MuiGrid-grid-xs-3 {
  max-width: 100%;
}
